import {mapGetters, mapMutations} from "vuex";


//sections


export default {
    name: "description",
    components: {},
    data() {
        return {
            renderContent: 1
        }
    },
    created() {

    },
    mounted() {
        document.title = `${this.currentProduct.title + ' - ' + this.$t('productCard.description.title')} `
        this.renderContent += 1
        let table = Array.from(document.querySelectorAll('table'))
        if (table) {
            table.forEach(el => {
                let wrapper = document.createElement('div')
                el.parentNode.appendChild(wrapper);
                wrapper.appendChild(el);
                let parrent = el.parentNode
                parrent.classList.add('table-wrapper')

            })
        }
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct'
        })
    },

    methods: {
        ...mapMutations({})
    }
}
